import React from "react";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-react-grid";
import { fetchGet } from "../utils/requestsHelper.jsx";
import GridLoadingPanel from "./gridLoadingPanel.jsx";
import ErrorMask from "./errorMask.jsx";
import { isMobileSize } from "../utils/systemConstants.jsx";
import { v4 as uuidv4 } from "uuid";

const createdSort = [{ field: "created", dir: "desc" }];

function getFilterByField(field, operator, value) {
    return {
        filters: [
            {
                logic: "and",
                filters: [
                    { "field": field, "operator": operator, "value": value }
                ]
            }
        ],
        logic: "and"
    };
}

const GridBaseComponent = forwardRef(function GridBaseComponent({
    baseUrl,
    sortable,
    filterable,
    pageable,
    take,
    sort,
    filter,
    children,
    className,
    style }, ref) {
    const [data, setData] = useState([]);
    const [dataState, setDataState] = useState({
        skip: 0,
        take: take == undefined ? 20 : take,
        sort: sort == undefined ? [] : sort,
        filter: filter == undefined ? [] : filter
    });
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);
    const isMounted = useRef(false);
    const gridId = useRef('gbc_' + uuidv4());

    useEffect(() => {
        isMounted.current = true;
        refreshDataSource();

        return function cleanup() {
            isMounted.current = false;
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            refreshDataSource,
            filterByField
        };
    })

    const props = {};
    if (className)
        props.className = className;

    if (style)
        props.style = style;

    if (error) {
        return <ErrorMask {...props} error={error} inline />;
    }

    const isMobile = isMobileSize(window.innerWidth, window.innerHeight);
    let pager = pageable;
    if (isMobile && pageable) {
        pager = {
            buttonCount: 9,
            info: false,
            type: "numeric",
            previousNext: false
        };
    }
    if (total <= dataState.take)
        pager = false;

    const panel = pending ? <GridLoadingPanel gridId={(gridId.current)} /> : null;
    return <>
        <Grid
            ref={ref}
            id={gridId.current}
            filterable={(filterable == undefined ? false : filterable)}
            sortable={(sortable == undefined ? true : sortable)}
            pageable={pager}
            {...props}
            total={total}
            data={data}
            skip={dataState.skip}
            pageSize={dataState.take}
            filter={dataState.filter}
            sort={dataState.sort}
            onDataStateChange={handleDataStateChange}>
            {children}
        </Grid>
        {panel}
    </>;

    function refreshDataSource() {
        fetchData(dataState);
    }

    function filterByField(field, operator, value) {
        dataState.filter = field
            ? getFilterByField(field, operator, value)
            : {};
        fetchData(dataState);
    }

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(changeEvent.dataState);
    }

    function fetchData(dataState) {
        if (!isMounted.current)
            return;

        const query = toDataSourceRequestString(dataState);
        const hasGroups = dataState.group && dataState.group.length;

        setPending(true);
        fetchGet(`${baseUrl}?${query}`,
            ({ data, total }) => {
                if (!isMounted.current)
                    return;

                setData(hasGroups ? translateDataSourceResultGroups(data) : data);
                setError(null);
                setTotal(total);
                setDataState(dataState);
                setPending(false);
            },
            data => setError(data));
    }
});

export {
    getFilterByField,
    createdSort,
    GridBaseComponent
};