import React from "react";
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import FileHelper from "../../utils/fileHelper.jsx";
import BarcodeHelper from "../../utils/barcodeHelper.jsx";
import { Toolbar } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DrawerMenu from "../../components/drawerMenu.jsx";
import { fetchPost } from "../../utils/requestsHelper.jsx";
import {
    eventResize,
    eventBeforeUnload,
    getStrategyEntity,
    specialTypes,
    entitySources,
    entitySourcesNames,
    getContainerStyle
} from "../../utils/systemConstants.jsx";
import { getUser } from "../../utils/authHelper.jsx";

import { renderField } from "./cardRenderFunctions.jsx";
import { renderState } from "./cardRenderState.jsx";
import { renderAction } from "./cardRenderActions.jsx";

import DialogLinkedClient from "../dialogs/dialogLinkedClient.jsx";
import DialogTask from "../dialogs/dialogTask.jsx";
import DialogHistory from "../dialogs/dialogHistory.jsx";
import DialogDocument from "../dialogs/dialogDocument.jsx";
import DialogProjectStage from "../dialogs/dialogProjectStage.jsx";
import DialogClientCopy from "../dialogs/dialogClientCopy.jsx";
import DialogLoanCopy from "../dialogs/dialogLoanCopy.jsx";
import DialogAddLinkedClient from "../dialogs/dialogAddLinkedClient.jsx";
import DialogAddCollateral from "../dialogs/dialogAddCollateral.jsx";
import DialogPromiseSchedulerAdder from "../dialogs/dialogPromiseSchedulerAdder.jsx";
import DialogProcess from "../dialogs/dialogProcess.jsx";
import DialogAccount from "../dialogs/dialogAccount.jsx";
import DialogCollateral from "../dialogs/dialogCollateral.jsx";
import DialogRecordPlay from "../dialogs/dialogRecordPlay.jsx";
import DialogRecordRating from "../dialogs/dialogRecordRating.jsx";
import QuestionnaireDialog from "../questionnaire/questionnaireDialog.jsx";
import DialogPaymentAdder from "../dialogs/dialogPaymentAdder.jsx";
import DialogStrategyHistory from "../dialogs/dialogStrategyHistory.jsx";
import DialogConfirmVerification from "../dialogs/dialogConfirmVerification.jsx";
import DialogMap from "../dialogs/dialogMap.jsx"
import StatisticDialog from "../statistic/statisticDialog.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";

import DocumentsControl from "./controls/documentsControl.jsx";
import TasksControl from "./controls/tasksControl.jsx";
import HistoryControl from "./controls/historyControl.jsx";
import LinkedPersonsControl from "./controls/linkedPersonsControl.jsx";
import LinkedDataControl from "./controls/linkedDataControl.jsx";
import CollateralsControl from "./controls/collateralsControl.jsx";
import CollateralSchedulerControl from "./controls/collateralSchedulerControl.jsx";
import ProcessControl from "./controls/processControl.jsx";
import AccountsControl from "./controls/accountsControl.jsx";
import PaymentsControl from "./controls/paymentsControl.jsx";
import PaymentPlanControl from "./controls/paymentPlanControl.jsx";
import PromiseSchedulerControl from "./controls/promiseSchedulerControl.jsx";
import DocumentPackageControl from "./controls/documentPackageControl.jsx";
import CollateralQueueControl from "./controls/collateralQueueControl.jsx";
import StageMovementsControl from "./controls/stageMovementsControl.jsx";
import ProcessMovementsControl from "./controls/processMovementsControl.jsx";
import CallRecordsControl from "./controls/callRecordsControl.jsx";
import SpecificModuleControl from "./controls/specificModuleControl.jsx";
import OwnerControl from "./controls/ownerControl.jsx";
import CardChatControl from "./controls/cardChatControl.jsx";

import {
    tabSelected,
    editMode,
    fileUpload,
    changedValue,
    callPhone,
    addContact,
    cardLoad,
    cardClosed,
    save,
    verify,
    validateClose,
    expandChange,
    resetStrategy,
    archiveOrRestore,
    dialogTask,
    dialogExport,
    dialogHistory,
    dialogDocument,
    dialogLoan,
    dialogEditLinkedClient,
    dialogAddLinkedClient,
    dialogAddCollateral,
    dialogAddCollateralOwner,
    dialogCopyClient,
    dialogCopyLoan,
    dialogProcess,
    dialogAccount,
    dialogEditCollateral,
    dialogPromiseSchedulerAdder,
    dialogPaymentAdder,
    dialogRecordPlay,
    dialogRecordRating,
    dialogQuestionnaire,
    dialogStrategyHistory,
    dialogConfirmVerification,
    dialogMap,
    windowSizeChanged
} from "./cardEntityReducer.jsx";

import "../../styles/css/cardSourceMovement.css";
import "../../styles/css/cardStyles.css";

export default function CardEntity() {
    const errorStyle = { height: "85px" };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cardIsUpdatedRef = useRef(false);

    const gridHistory = useRef(null);
    const gridCollateral = useRef(null);
    const gridOwners = useRef(null);
    const gridDocuments = useRef(null);
    const gridLinkedClients = useRef(null);
    const gridTasks = useRef(null);
    const gridPayments = useRef(null);
    const gridProcess = useRef(null);
    const gridAccounts = useRef(null);

    const params = useParams();
    const prevParams = useRef();
    const card = useSelector(state => state.cardEntity);

    useEffect(() => {
        window.addEventListener(eventResize, handleWindowSizeChange);
        window.addEventListener(eventBeforeUnload, formClosed);
        document.title = `Delta M. Crm. ${window.captions.NewCard}`;

        return function cleanup() {
            window.removeEventListener(eventResize, handleWindowSizeChange);
            window.removeEventListener(eventBeforeUnload, formClosed);
        };
    }, []);

    useEffect(() => {
        if (prevParams.id === params.id && prevParams.entity === params.entity && !card.needReload)
            return;

        dispatch(cardLoad(params.id, params.entity, card.needReload));

        prevParams.id = params.id;
        prevParams.entity = params.entity;
        prevParams.needReload = card.needReload;

        return () => {
            dispatch(cardClosed({
                entity: params.entity,
                id: params.id
            }));
        }
    }, [params.id, params.entity, card.needReload]);

    useEffect(() => {
        if (card.hasClientDuplicateByInn && confirm(window.captions.FoundDuplicateQuestion))
            dispatch(save(true));
    }, [card.hasClientDuplicateByInn]);

    useEffect(() => {
        if (card.needReload && (params.id != card.id || params.entity != card.entity)) {
            navigate(`/card/${card.entity}/${card.id}`);
        }
    }, [card.needReload]);

    useEffect(() => { cardIsUpdatedRef.current = card.isUpdated; }, [card]);

    const isMobile = card.isMobile;
    if (card.isError) {
        return <ErrorMask error={card.error} />;
    }

    if (!card.isLoaded) {
        return <LoadingMask />;
    }

    const model = card.model;
    if (!model)
        return <div>Empty model</div>;

    if (model.hasAccessError)
        return <ErrorMask error={model.accessError} />;

    var title = getTitle(model);
    document.title = `Delta M. Crm. ${title}`;

    let savingState = "";
    var controls = [];
    if (card.isProcessing) {
        savingState = <LoadingMask text={`${window.captions.SavingData} ${card.id}`} />;
    } else {
        renderAction(card, controls, model.isReadOnly);
    }

    var statusBar = renderState(isMobile, card, dispatch);
    let dialogContent = "";
    let inlineDialogContent = false;
    if (card.dialogs.validate) {
        dialogContent = <ValidationMessage title={model.name}
            close={() => dispatch(validateClose())}
            text={card.dialogs.validate.message}
            error={card.dialogs.validate.isError} />;
    } else if (card.dialogs.tasks) {
        const projectId = card.entity === entitySourcesNames.loan ? model.projectId : 0;
        inlineDialogContent = card.sizeMode.inlineDialog;
        dialogContent = <DialogTask inline={card.sizeMode.inlineDialog}
            cardId={card.id}
            taskId={card.dialogs.taskId}
            projectId={projectId}
            cardEntity={card.entity}
            onClose={(success) => closeTasks(success)} />;
    } else if (card.dialogs.export) {
        dialogContent = <StatisticDialog cardId={card.id}
            exportType={card.dialogs.export.exportType}
            id={card.dialogs.export.id}
            cardEntity={card.entity}
            onClose={() => dispatch(dialogExport(false, 0))} />;
    } else if (card.dialogs.document) {
        inlineDialogContent = card.sizeMode.inlineDialog;
        dialogContent = <DialogDocument inline={card.sizeMode.inlineDialog}
            cardId={card.id}
            cardEntity={card.entity}
            onClose={(success) => closeDocument(success)} />;
    } else if (card.dialogs.addLinked) {
        dialogContent = <DialogAddLinkedClient cardId={card.id}
            cardEntity={card.entity}
            canAdd={true}
            canSelectType={true}
            onClose={(data) => closeLinkedClientsAdd(data)} />;
    } else if (card.dialogs.addOwner) {
        dialogContent = <DialogAddLinkedClient cardId={card.id}
            cardEntity={card.entity}
            canAdd={true}
            canSelectType={false}
            onClose={(data) => closeOwnerAdd(data)} />;
    } else if (card.dialogs.editLinked) {
        dialogContent = <DialogLinkedClient cardId={card.id}
            cardEntity={card.entity}
            linkEntity={card.dialogs.linkedSource}
            id={card.dialogs.linkedCardId}
            linkId={card.dialogs.linkedId}
            onClose={(success) => closeLinkedClientsEdit(success)} />;
    } else if (card.dialogs.addCollateral) {
        dialogContent = <DialogAddCollateral cardId={card.id}
            cardEntity={card.entity}
            onClose={(data) => closeCollateralAdd(data)} />;

    } else if (card.dialogs.editCollateral) {
        dialogContent = <DialogCollateral collateralId={card.dialogs.collateralId}
            cardId={card.id}
            cardEntity={card.entity}
            projectId={model.projectId}
            onClose={(success) => closeCollateralEdit(success)} />;

    } else if (card.dialogs.addPromiseScheduler) {
        dialogContent = <DialogPromiseSchedulerAdder cardId={card.id}
            projectId={model.projectId}
            amountDebtInitial={model.amountDebtInitial}
            amountDebtMax={model.amountDebtMax}
            onClose={() => dispatch(dialogPromiseSchedulerAdder(false))} />;
    } else if (card.dialogs.addPayment) {
        inlineDialogContent = card.sizeMode.inlineDialog;
        dialogContent = <DialogPaymentAdder inline={card.sizeMode.inlineDialog}
            loanIds={[card.id]}
            projectId={model.projectId}
            stageId={model.stageId}
            onClose={(success) => closePayments(success)} />;
    } else if (card.dialogs.process) {
        dialogContent = <DialogProcess processId={card.dialogs.processId}
            cardId={card.id}
            cardEntity={card.entity}
            access={model.access}
            projectId={model.projectId}
            onClose={(success) => closeProcess(success)} />;
    } else if (card.dialogs.account) {
        dialogContent = <DialogAccount accountId={card.dialogs.accountId}
            cardId={card.id}
            cardEntity={card.entity}
            access={model.access}
            onClose={(success) => closeAccount(success)} />;
    } else if (card.dialogs.record) {
        dialogContent = <DialogRecordPlay link={model.externalId}
            onClose={() => dispatch(dialogRecordPlay(false))} />;

    } else if (card.dialogs.recordRating) {
        dialogContent = <DialogRecordRating cardId={card.id}
            ratingId={model.ratingId}
            canEdit={model.access.canEditRecordsRating}
            onClose={() => dispatch(dialogRecordRating(false))} />;

    } else if (card.dialogs.questionnaire) {
        dialogContent = <QuestionnaireDialog id={card.dialogs.questionnaire.id}
            cardId={card.id}
            cardEntity={card.entity}
            questionnaires={model.questionnaires}
            onClose={() => dispatch(dialogQuestionnaire(false, 0))} />;

    } else if (card.dialogs.strategy) {
        dialogContent = <DialogStrategyHistory entityId={card.id}
            entity={getStrategyEntity(card.entity)}
            onClose={() => dispatch(dialogStrategyHistory(false))} />;

    } else if (card.dialogs.history || (card.openParameters.isContactOpened && !card.isEditMode)) {
        var clientId = model.id;
        if (card.entity === entitySourcesNames.loan)
            clientId = model.clientId;

        inlineDialogContent = card.sizeMode.inlineDialog;
        dialogContent = <DialogHistory inline={card.sizeMode.inlineDialog}
            cardId={card.id}
            cardEntity={card.entity}
            sources={model.sources}
            stageId={model.stageId}
            projectId={model.projectId}
            clientId={clientId}
            clientName={model.name}
            phones={model.phones}
            addresses={model.addresses}
            emails={model.emails}
            urls={model.urls}
            amountDebtInitial={model.amountDebtInitial}
            amountDebtMax={model.amountDebtMax}
            options={card.openParameters}
            canAddSchedule={model.hasPromiseToAdd}
            stateId={model.stateId}
            activeTasks={model.activeTasks}
            onClose={(data) => closeHistory(data)} />;
    } else if (card.dialogs.loan) {
        var copyLoanClientId = model.id;
        if (card.entity === entitySourcesNames.loan)
            copyLoanClientId = model.clientId;

        dialogContent = <DialogProjectStage clientId={copyLoanClientId}
            onClose={(id) => dispatch(dialogLoan(false, id))} />;
    } else if (card.dialogs.clientCopy) {
        var copyClientId = model.id;
        if (card.entity === entitySourcesNames.loan)
            copyClientId = model.clientId;

        dialogContent = <DialogClientCopy clientId={copyClientId}
            onClose={(id) => dispatch(dialogCopyClient(false, id))} />;
    } else if (card.dialogs.loanCopy && card.entity === entitySourcesNames.loan) {
        dialogContent = <DialogLoanCopy loanId={model.id}
            projectId={model.projectId}
            stageId={model.stageId}
            stateId={model.stateId}
            onClose={(loanModel) => dispatch(dialogCopyLoan(false, loanModel))} />;
    } else if (card.dialogs.verification && card.dialogs.verificationPhoneId > 0) {
        const phoneId = card.dialogs.verificationPhoneId;
        dialogContent = <DialogConfirmVerification phoneId={phoneId}
            code={card.dialogs.verificationCode}
            altCode={card.dialogs.altVerificationCode}
            onClose={(verified, stateId) => dispatch(dialogConfirmVerification(
                false,
                phoneId,
                "",
                "",
                verified,
                stateId))} />;
    } else if (card.dialogs.map && card.dialogs.mapPosition) {
        dialogContent = <DialogMap position={card.dialogs.mapPosition}
            onClose={() => dispatch(dialogMap(false, null))} />;
    }

    let controlsBar = null;
    if (isMobile) {
        var selectedTabPanel = model.tabs.find(i => i.index === card.selectedTab);
        controlsBar = <Toolbar>
            <DropDownList
                data={model.tabs}
                textField="name"
                dataItemKey="index"
                style={{ width: "100%" }}
                value={selectedTabPanel}
                onChange={(e) => dispatch(tabSelected(e))} />
        </Toolbar>;
    }

    const tabs = renderTabs();
    var rightMenu = controls.length > 0
        ? <DrawerMenu items={controls}
            align="right"
            isIcon={true}
            isMobile={isMobile}
            isSmall={card.expanded === false}
            onSelect={onMenuExecute}>
        </DrawerMenu>
        : null;

    const statusBarControl = statusBar.length > 0
        ? <Toolbar>
            {statusBar}
        </Toolbar>
        : null;

    const tabsMenu = tabs.items.length > 1 && !isMobile
        ? <DrawerMenu items={tabs.items}
            align="left"
            onSelect={(e) => dispatch(tabSelected(e))}>
        </DrawerMenu>
        : null;

    var dialogInline = null;
    if (dialogContent && inlineDialogContent) {
        dialogInline = dialogContent;
        rightMenu = null;
        dialogContent = null;
    }

    return <div className="dm-full-height">
        {tabsMenu}
        <div className="dm-full-height">
            <h1 className="dm-title dm-card-title">{title}</h1>
            <div className="dm-under-title-content">
                <div className="dm-flex-height">
                    {controlsBar}
                    <div className="dm-flex-height-content">
                        {rightMenu}
                        {dialogInline}
                        {tabs.selected}
                    </div>
                    {statusBarControl}
                </div>
                {dialogContent}
                {savingState}
            </div>
        </div>
    </div>;

    function formClosed(e) {
        if (!cardIsUpdatedRef.current)
            return null;

        if (getUser())
            fetchPost(window.constants.tabClosed, window.location.pathname, () => { }, () => { });

        e.preventDefault();
        e.returnValue = "Can close?";
        return e.returnValue;
    }

    function handleWindowSizeChange() {
        dispatch(windowSizeChanged(card.expanded));
    }

    function onMenuExecute(e) {
        const action = e.action;
        const id = e.actionid;
        switch (action) {
            case "History":
                dispatch(dialogHistory(true));
                break;
            case "Task":
                dispatch(dialogTask(true, 0));
                break;
            case "ExportDocument":
            case "ExportDocumentItem":
            case "ExportDocumentFolder":
            case "ExportReport":
            case "ExportReportItem":
            case "ExportReportFolder":
                dispatch(dialogExport(action, id));
                break;
            case "Document":
                dispatch(dialogDocument(true));
                break;
            case "Loan":
                dispatch(dialogLoan(true, 0));
                break;
            case "CopyLoan":
                dispatch(dialogCopyLoan(true, 0));
                break;
            case "CopyClient":
                dispatch(dialogCopyClient(true, 0));
                break;
            case "AddLinkedClient":
                dispatch(dialogAddLinkedClient(true));
                break;
            case "AddCollateral":
                dispatch(dialogAddCollateral(true));
                break;
            case "AddCollateralOwner":
                dispatch(dialogAddCollateralOwner(true));
                break;
            case "AddProcess":
                dispatch(dialogProcess(true, 0));
                break;
            case "AddAccount":
                dispatch(dialogAccount(true, 0));
                break;
            case "StrategyRegister":
                dispatch(dialogStrategyHistory(true));
                break;
            case "StrategyReset":
                if (card.entity === entitySourcesNames.loan && confirm(window.captionsDynamic.ResetStrategyQuestion) ||
                    card.entity === entitySourcesNames.client && confirm(window.captions.ResetClientStrategyQuestion) ||
                    card.entity === entitySourcesNames.collateral &&
                    confirm(window.captionsDynamic.ResetCollateralStrategyQuestion)) {
                    dispatch(resetStrategy(card.entity, card.id));
                }
                break;
            case "AddPromiseScheduler":
                dispatch(dialogPromiseSchedulerAdder(true));
                break;
            case "AddPayment":
                dispatch(dialogPaymentAdder(true));
                break;
            case "RecordPlay":
                dispatch(dialogRecordPlay(true, ""));
                break;
            case "RecordRating":
                dispatch(dialogRecordRating(true));
                break;
            case "Questionnaire":
                dispatch(dialogQuestionnaire(true, id));
                break;
            case "Edit":
                dispatch(editMode(true, card.model));
                break;
            case "View":
                dispatch(editMode(false, card.model));
                break;
            case "Save":
                saveCard();
                break;
            case "Archive":
            case "ArchiveRestore":
                dispatch(archiveOrRestore(card.entity, card.id, !card.model.isArchived));
                break;
            case "ExpandChange":
                dispatch(expandChange(card.expanded));
                break;
            case "DownloadDocument":
                FileHelper.getDocument(card.model.id);
                break;
            case "PrintBarcode":
                BarcodeHelper.printBarcode(card.model.id, card.model.barcodeFields);
                break;
        }
    }

    function getTitle(model) {
        switch (model.source) {
            case entitySources.collateral:
                if (model.name)
                    return model.name;
                else
                    return window.captionsDynamic.Collaterals;
            case entitySources.callRecords:
                return window.captions.CardCallRecords;
            default:
                if (model.name)
                    return model.name;
                else
                    return window.captions.NewCard;
        }
    }

    function saveCard(canUniteByInn = false) {
        dispatch(save(card.model, card.entity, card.openParameters, canUniteByInn));
    }

    function refreshGrid(grid) {
        if (grid && grid.current)
            grid.current.refreshDataSource();
    }

    function closeDocument(success) {
        dispatch(dialogDocument(false));
        if (success !== false)
            refreshGrid(gridDocuments);
    }

    function closeHistory(successAdd) {
        dispatch(dialogHistory(false));
        if (successAdd === true)
            refreshGrid(gridHistory);
    }

    function closeLinkedClientsAdd(data) {
        if (data && data.typeId > 0)
            dispatch(dialogEditLinkedClient(true, data.id, 0, 0));
        else {
            dispatch(dialogAddLinkedClient(false));
            refreshGrid(gridLinkedClients);
        }
    }

    function closeCollateralAdd(id) {
        if (id >= 0) {
            dispatch(dialogEditCollateral(true, id));
        } else {
            dispatch(dialogAddCollateral(false));
        }
    }

    function closeOwnerAdd(data) {
        dispatch(dialogAddCollateralOwner(false));
        refreshGrid(gridOwners);
        if (data && data.typeId > 0) {
            dispatch(dialogEditLinkedClient(true, data.id, entitySources.collateralOwner, 0));
        }
    }

    function closeCollateralEdit(success) {
        dispatch(dialogEditCollateral(false, 0));
        if (success !== false)
            refreshGrid(gridCollateral);
    }

    function closeLinkedClientsEdit(success) {
        dispatch(dialogEditLinkedClient(false, 0, 0, 0));
        if (success !== false)
            refreshGrid(gridLinkedClients);
    }

    function closeTasks(success) {
        dispatch(dialogTask(false, 0));
        if (success !== false)
            refreshGrid(gridTasks);
    }

    function closePayments(success) {
        dispatch(dialogPaymentAdder(false));
        if (success !== false)
            refreshGrid(gridPayments);
    }

    function closeProcess(success) {
        dispatch(dialogProcess(false));
        if (success !== false)
            refreshGrid(gridProcess);
    }

    function closeAccount(success) {
        dispatch(dialogAccount(false));
        if (success !== false)
            refreshGrid(gridAccounts);
    }

    function renderControl(field, i) {
        const model = card.model;
        const sources = model.sources;
        const id = card.id;
        const source = card.entity;
        const isEditMode = card.isEditMode;
        const access = model.access;
        switch (field.type) {
            case specialTypes.separator:
                return <hr key={i} />;
            case specialTypes.fullHistory:
                return access.canViewHistory
                    ? <HistoryControl key={i}
                        source={source}
                        id={id}
                        dialogMap={(isShow, position) => dispatch(dialogMap(isShow, position))}
                        ref={gridHistory}
                        isEditMode={isEditMode} />
                    : <ErrorMask key={i} error={window.captions.ViewAccessDeny} style={errorStyle} />;
            case specialTypes.payments:
                return <PaymentsControl key={i}
                    id={id}
                    projectId={model.projectId}
                    ref={gridPayments}
                    canDelete={access.canDeletePayments} />;
            case specialTypes.stageMovements:
                return <StageMovementsControl key={i} source={source} id={id} />;
            case specialTypes.documents:
                return <DocumentsControl key={i}
                    source={source}
                    id={id}
                    ref={gridDocuments}
                    isEditMode={isEditMode}
                    canOpenCard={access.canOpenCardDocument} />;
            case specialTypes.process:
                return <ProcessControl key={i}
                    source={source}
                    id={id}
                    canDelete={access.canDeleteProcess}
                    ref={gridProcess}
                    isEditMode={isEditMode}
                    processViewEdit={(itemId) => dispatch(dialogProcess(true, itemId))} />;
            case specialTypes.linkedData:
                return <LinkedDataControl key={i} source={source} id={id}
                    canOpenCard={access.canOpenLinkedDataCard} />;
            case specialTypes.collaterals:
                return <CollateralsControl key={i}
                    source={source}
                    id={id}
                    ref={gridCollateral}
                    isEditMode={isEditMode}
                    collateralViewEdit={(itemId) => dispatch(dialogEditCollateral(true, itemId))}
                    canOpenCard={access.canOpenCardCollateral} />;
            case specialTypes.paymentPlan:
                return <PaymentPlanControl key={i} id={id} projectId={model.projectId} />;
            case specialTypes.linkedClient:
                return <LinkedPersonsControl key={i}
                    source={source}
                    sources={sources}
                    id={id}
                    ref={gridLinkedClients}
                    isEditMode={isEditMode}
                    edit={(linkCardId, linkSource, linkId) => dispatch(dialogEditLinkedClient(true,
                        linkCardId,
                        linkSource,
                        linkId))} />;
            case specialTypes.callsRecords:
                return access.canViewCallRecords
                    ? <CallRecordsControl key={i} source={source} id={id}
                        canListen={access.canListenCallRecords}
                        canOpenCard={access.canOpenCardCallRecords} />
                    : <ErrorMask key={i} error={window.captions.ViewAccessDeny} style={errorStyle} />;
            case specialTypes.settedTasks:
                return <TasksControl key={i}
                    source={source}
                    id={id}
                    ref={gridTasks}
                    isEditMode={isEditMode}
                    taskEdit={(taskId) => dispatch(dialogTask(true, taskId))} />;
            case specialTypes.historyEvents:
                return <ErrorMask key={i} error="Unfinished HistoryEvents" style={errorStyle} />;
            case specialTypes.promiseScheduler:
                return <PromiseSchedulerControl key={i}
                    id={id}
                    canCancel={access.canCancelPromiseScheduler} />;
            case specialTypes.specificModule:
                return <SpecificModuleControl key={i} id={id} module={field.modelName} source={source} />;
            case specialTypes.processMovements:
                return <ProcessMovementsControl key={i} id={id} />;
            case specialTypes.documentPackages:
                return <DocumentPackageControl key={i} source={source} id={id} />;
            case specialTypes.accounts:
                return <AccountsControl key={i}
                    source={source}
                    id={id}
                    canDelete={access.canDeleteAccount}
                    ref={gridAccounts}
                    isEditMode={isEditMode}
                    accountViewEdit={(itemId) => dispatch(dialogAccount(true, itemId))} />;
            case specialTypes.loanAgreement:
                return <ErrorMask key={i} error="Unfinished Agreement" style={errorStyle} />;
            case specialTypes.collateralScheduler:
                return <CollateralSchedulerControl key={i} id={id} isEditMode={isEditMode}
                    stateId={model.stateId}
                    canReserve={access.canCollateralReserve}
                    canDeleteReserve={access.canDeleteCollateralReserve
                    } />;
            case specialTypes.collateralQueue:
                return <CollateralQueueControl key={i}
                    id={id}
                    canDelete={access.canDeleteQueueReserve} />;
            case specialTypes.collateralOwners:
                return <OwnerControl key={i}
                    source={source}
                    sources={sources}
                    id={id}
                    ref={gridOwners}
                    isEditMode={isEditMode}
                    edit={(linkId) => dispatch(dialogEditLinkedClient(true,
                        linkId,
                        entitySources.collateralOwner,
                        0))} />;
            case specialTypes.loanChat:
                return access.canViewChat
                    ? <CardChatControl key={i} source={source} id={id} />
                    : <ErrorMask key={i} error={window.captions.ViewAccessDeny} style={errorStyle} />;
            default:
                return <ErrorMask key={i} error={`Wrong component. Type: ${field.type}`} style={errorStyle} />;
        }
    }

    function renderFieldControl(field, key) {
        if (field.isControl)
            return renderControl(field, key);

        const model = card.model;
        const fieldModel = model.fields[field.modelName];
        if (!fieldModel)
            return [];

        return renderField(fieldModel,
            key,
            model.sources,
            model.phones,
            model.addresses,
            model.emails,
            model.urls,
            model.accounts,
            card.isEditMode,
            { entity: card.entity, entityId: card.model.id },
            {
                dialogConfirmVerification: (isShow, phoneId, code, altCode, verified, stateId) => dispatch(dialogConfirmVerification(isShow, phoneId, code, altCode, verified, stateId)),
                callPhone: (e) => dispatch(callPhone(e)),
                addContact: (fieldId, typeItem) => dispatch(addContact(fieldId, typeItem)),
                changedValue: (e) => dispatch(changedValue(e)),
                fileUpload: (e) => dispatch(fileUpload(e)),
                verify: (model, success) => dispatch(verify(model, success))
            });
    }

    function renderTabs() {
        var tabs = {
            items: [],
            selected: null
        };

        const selectedIndex = card.selectedTab >= card.model.tabs.length ? 0 : card.selectedTab;
        card.model.tabs.forEach((item) => tabs.items.push({
            text: item.name,
            id: item.index,
            selected: item.index === selectedIndex
        }));

        var containerClass = getContainerStyle(card.sizeMode);
        const tab = card.model.tabs.find(i => i.index === card.selectedTab);
        if (tab) {
            const content = tab.items.map((field, key) => renderFieldControl(field, key));
            tabs.selected = tab.items.length === 1 && tab.items[0].isControl
                ? <div className="dm-container-full-size">
                    {content}
                </div>
                : <div className="db-overflow-y">
                    <div className={containerClass}>
                        {content}
                    </div>
                </div>;
        }

        return tabs;
    }
}